import React, { useEffect } from "react";
import ContactBanner from "../assets/contact-banner.png";
import { IoCallSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="contact-banner">
        <img src={ContactBanner} alt="" />
        <div className="contact-container">
          <h1 className="heading-h1 text-white">CONTACT US</h1>
          <h5 className="heading-h5">Get Intouch</h5>
        </div>
      </div>
      <div className="container-section margin-100">
        <div className="row">
          <div className="col-md-4  col-sm-12">
            <div className="contact-content">
              <h3 className="heading-h3">
                <IoCallSharp />
              </h3>
              <h4 className="heading-h4 text-center" style={{ float: "unset" }}>
                PHONE
              </h4>{" "}
              <br />
              <p className="para">
                A wonderful serenity has taken possession of my entire soul,
                like these sweet mornings.
              </p>
              <p > <a href="tel:+916200212563" className="color-black">+91 6200212563</a></p>
            </div>
          </div>
          <div className="col-md-4  col-sm-12">
            <div className="contact-content">
              <h3 className="heading-h3">
                <MdOutlineMail />
              </h3>
              <h4 className="heading-h4 text-center" style={{ float: "unset" }}>
                EMAIL
              </h4>{" "}
              <br />
              <p className="para">
                A wonderful serenity has taken possession of my entire soul,
                like these sweet mornings.
              </p>
              <p ><a href="mailto:info@trypscanner.com" className="color-black">info@TrypScanner.com</a></p>
            </div>
          </div>
          <div className="col-md-4  col-sm-12">
            <div className="contact-content">
              <h3 className="heading-h3">
                <FaLocationDot />
              </h3>
              <h4 className="heading-h4 text-center" style={{ float: "unset" }}>
                LOCATION
              </h4>{" "}
              <br />
              <p className="para">
                4 apt. Flawing Street. The Grand Avenue. Liverpool, UK 33342
              </p>
              <p >


                <a href="https://maps.app.goo.gl/1tLBSsTRGX2GiGRH7" className="color-black" target="_blank"> View On Google Map</a>
              </p>

            </div>
          </div>
        </div>
      </div>

      {/* contact-form */}
      <div className="browser-tour-section margin-100">
        <div className="container-section">
          <div className="contact-form-section">
            <h2 className="heading-h2">LEAVE US YOUR INFO</h2>
            <p className="para">And we will get back to you.</p>
            <div className="contact-form-container">
              <div>
                <input type="text" placeholder="Full Name" name="" id="" />
                <input type="email" placeholder="Email" name="" id="" />
              </div>
              <div>
                <input type="text" placeholder="Subject" name="" id="" />
              </div>
              <div>
                <textarea
                  name=""
                  placeholder="Message"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div>
                <button className="search-btn">SUBMIT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
