import React, { useState } from "react";
import HomeBanner from "../assets/home-banner.png";
import HomeBanner2 from "../assets/home-banner2.png";
import Destination from "../assets/destination.png";
import Guarantee from "../assets/guarantee.png";
import Communication from "../assets/communication.png";
import { TripData } from "../tripsdetails/TripData";
import { Link, useNavigate } from "react-router-dom";
export const HomeSlider = () => {
  const results = TripData
  const navigate = useNavigate()
  const [open, setOpen] = useState()
  const [searchQuery, setSearchQuery] = useState('');
  const searchData = [];
  results.forEach(item => {
    searchData.push(item.tripSubTours);
  });

  const subdata = searchData.flat()
  // console.log("", searchData.flat())

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);

  };
  const handleOpen = () => setOpen(!open)
  // Step 3: Filter data based on search query
  const filteredData = subdata.filter((item) =>
    item.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  console.log("se", searchQuery)
  return (
    <>
      <div className="home-banner">
        <img src={HomeBanner} alt="" />

        <div className="slider-container">
          <h1 className="heading-h1 text-black">Book Your Next Place</h1>
          <p className="text-black">Discover amzaing places at exclusive deals</p>
          <div className="slider-search-container d-flex">
            <div className="position-relative">
              <input type="text" placeholder="Search Keyword" value={searchQuery}
                onChange={handleSearchInputChange} name="" id="" onClick={handleOpen} />
              <ul className={open ? "search-sugest open" : "search-sugest"}>
                {filteredData.map((item, i) => (
                  <li key={i} value={item.name} onClick={() => setSearchQuery(item.name)}><Link to={item.links}>{item.name}</Link></li>
                ))}
              </ul>

            </div>
            <select name="" id="">
              {results.map((item, i) => (
                <option value="" key={i} >
                  <Link to={item.url}> {item.title}</Link></option>
              ))}
              {/* <option value="">Goa</option>
              <option value="">Ladakh</option>
              <option value="">Kasmir</option> */}
            </select>
            <button className="search-btn">Search</button>
          </div>
        </div>
      </div>
      <div className="texture-area">
        <div className="texture-container">
          <div className="texture-content">
            <img src={Destination} alt="" />
            <div className="contents">
              <h5 className="heading-h5">700+ DESTINATIONS</h5>
              <p className="para">
                {" "}
                Our expert team handpicked all destinations in this site
              </p>
            </div>
          </div>
          <div className="texture-content">
            <img src={Guarantee} alt="" />
            <div className="contents">
              <h5 className="heading-h5">BEST PRICE GUARANTEE</h5>
              <p className="para">
                {" "}
                Price match within 48 hours of order confirmation
              </p>
            </div>
          </div>
          <div className="texture-content">
            <img src={Communication} alt="" />
            <div className="contents">
              <h5 className="heading-h5">TOP NOTCH SUPPORT</h5>
              <p className="para">
                {" "}
                We are here to help, before, during, and even after your trip.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSlider;
