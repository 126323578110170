import React, { Fragment, useEffect } from "react";
import KashmirBannerImg from "../assets/ladakh.avif";
import Manali from "../assets/manali.jpg";

import { IoTimeOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { LuIndianRupee } from "react-icons/lu";
import { VscSymbolEvent } from "react-icons/vsc";
import { TripData } from "./TripData"
import MetaData from "../layout/MetaData";
const ToursPage = ({ tripSubTours, title, bannerImg, }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);


    function calculateDiscountedPrice(originalPrice, discountPercentage) {
        // Convert the discount percentage to a decimal
        const discountDecimal = discountPercentage / 100;
        // Calculate the discount amount
        const discountAmount = originalPrice * discountDecimal;
        // Subtract the discount amount from the original price to get the discounted price
        const discountedPrice = originalPrice + discountAmount;
        return discountedPrice;
    }


    return (
        <>
            <MetaData title={`TrypScanner ${title}`} />
            <div className="about-banner">
                <img src={bannerImg} alt="" />
                <div className="container-section">
                    <div className="tour-banner-content">
                        <p className="para fs-5 pb-0 mb-0">Justo Vulputate Vehicula</p>
                        <h1 className="heading-h1 ">{title}</h1>
                    </div>
                </div>
            </div>

            {/* popular */}
            <div className="container-section mt-5">
                <div className="home-destination-section position-relative">
                    <h2 className="heading-h2 text-center">{title} Tours</h2>
                    <span className="text-heading-span text-center blur-text">{title}</span>
                    <div className="row margin-50 ">
                        {tripSubTours.map((items, i) => (
                            <div className="col-md-4 col-sm-12 mb-4" key={i}>
                                <div className="popular-tour-img-box">
                                    <div className="popular-img">
                                        <Link to={items.links}>
                                            <img src={items.photo} alt="" />
                                        </Link>
                                        {/* {console.log("object", items.tripDetailsData)} */}
                                    </div>
                                    <div class="tour-content">
                                        <h5 className="heading-h5 mb-4">
                                            <Link to={items.links} className="text-black">
                                                <span>
                                                    <VscSymbolEvent className="event-icon" />
                                                </span>
                                                {items.name}
                                            </Link>
                                        </h5>

                                        <div className="d-flex justify-content-between">
                                            <div >
                                                <div>
                                                    <span>
                                                        <IoTimeOutline className="clock-icon" />
                                                    </span>
                                                    <span>{items.days}</span>
                                                </div>
                                                <div className="review-content">
                                                    <div>
                                                        <span>
                                                            <FaStar className="star-icon" />
                                                        </span>
                                                        <span>
                                                            <FaStar className="star-icon" />
                                                        </span>
                                                        <span>
                                                            <FaStar className="star-icon" />
                                                        </span>
                                                        <span>
                                                            <FaStar className="star-icon" />
                                                        </span>
                                                        <span>
                                                            <FaStar className="star-icon" />
                                                        </span>
                                                        <span>({items.totalReview} Review)</span>
                                                    </div>


                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <p className="para less-price-div" style={{ marginBottom: "2px" }}>
                                                        <s>
                                                            ₹  {Math.floor(calculateDiscountedPrice(Number(items.price), items.discount)).toLocaleString()}/-

                                                        </s>
                                                    </p>
                                                </div>
                                                <h4 className="heading-h4">
                                                    <LuIndianRupee />  {(items.price).toLocaleString()}/-
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </div>
                </div>
            </div>
        </>
    );
};

export default ToursPage;
