import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Manali from "../assets/manali.jpg";
import Kasmir from "../assets/slider1.jpg";
import Ladakh from "../assets/ladakh.avif";
import { TripData } from "../tripsdetails/TripData";
const PopularDestination = () => {
  return (
    <>
      <div className="container-section margin-50">
        <div className="home-destination-section">
          <h2 className="heading-h3 text-center">Popular Destinations</h2>
          <div className="row ">
            {TripData.map((item, i) => (
              < Fragment key={i} >
                {i === 0 ? (
                  <div className="col-md-8 mt-4 col-sm-12" >
                    <div className="popular-img-box">
                      <Link to={item.url}>
                        <img src={item.img} alt="" />
                        <span className="tour-count">{item?.tripSubTours?.length} Tours</span>

                        <h5 className="heading-h5 show-and-remove">{item.title}</h5>
                        <div class="transition overlay ">
                          <div className="slow-animations">
                            <h5 className="heading-h5">{item.title}</h5>
                            <p className="para">
                              {item.description}
                            </p>
                            <Link to={item.url} className="view-tour">
                              View Tour
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 col-sm-12 mt-4">
                    <div className="popular-img-box">
                      <Link to={item.url}>
                        <img src={item.img} alt="" />
                        <span className="tour-count">{item?.tripSubTours?.length} Tours</span>
                        {/* {console.log("object", item?.tripSubTours?.length)} */}
                        <h5 className="heading-h5 show-and-remove">{item.title}</h5>
                        <div class="transition overlay">
                          <div className="slow-animations">
                            <h5 className="heading-h5">{item.title}</h5>
                            <p className="para">
                              {item.description}
                            </p>
                            <Link to={item.url} className="view-tour">
                              View Tour
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                )}
              </ Fragment>
            ))}
            {/* 
            <div className="col-md-4 col-sm-12 mt-4">
              <div className="popular-img-box">
                <Link to="/kashmir">
                  <img src={Kasmir} alt="" />
                  <span className="tour-count">3 Tours</span>
                  <h5 className="heading-h5 show-and-remove">Kasmir</h5>
                  <div class="transition overlay">
                    <h5 className="heading-h5">Kasmir</h5>
                    <p className="para">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separate.
                    </p>
                    <Link to="" className="view-tour">
                      View Tour
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mt-4">
              <div className="popular-img-box">
                <Link to="/ladakh">
                  <img src={Ladakh} alt="" />
                  <span className="tour-count">3 Tours</span>
                  <h5 className="heading-h5 show-and-remove">Ladakh</h5>
                  <div class="transition overlay">
                    <h5 className="heading-h5">Ladakh</h5>
                    <p className="para">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separate.
                    </p>
                    <Link to="" className="view-tour">
                      View Tour
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mt-4">
              <div className="popular-img-box">
                <Link to="/spiti-valley">
                  <img src={Manali} alt="" />
                  <span className="tour-count">3 Tours</span>
                  <h5 className="heading-h5 show-and-remove">Spiti Valley</h5>
                  <div class="transition overlay">
                    <h5 className="heading-h5">Spiti Valley</h5>
                    <p className="para">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separate.
                    </p>
                    <Link to="/spiti-valley" className="view-tour">
                      View Tour
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mt-4">
              <div className="popular-img-box">
                <Link to="/kedarnath">
                  <img src={Manali} alt="" />
                  <span className="tour-count">3 Tours</span>
                  <h5 className="heading-h5 show-and-remove">
                    Kedarnath / 4Dham
                  </h5>
                  <div class="transition overlay">
                    <h5 className="heading-h5">Kedarnath / 4Dham</h5>
                    <p className="para">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separate.
                    </p>
                    <Link to="/kedarnath" className="view-tour">
                      View Tour
                    </Link>
                  </div>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularDestination;
