import React from "react";
import { Link } from "react-router-dom";
import AboutBannerImg from "../assets/about-banner.png";
import AboutImg from "../assets/manali.jpg";
import Manali from "../assets/manali.jpg";
import { FaCircleCheck } from "react-icons/fa6";
const AboutBanner = () => {
  return (
    <>
      <div className="about-banner">
        <img src={AboutBannerImg} alt="" />
        <div className="container-section">
          <div>
            <h1 className="heading-h1">About Us</h1>
            <p className="para">Justo Vulputate Vehicula</p>
          </div>
        </div>
      </div>

      {/* about section 1 */}
      <div className="about-section margin-100">
        <div className="container-section">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img src={AboutImg} alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="ms-4">
                <h2 className="heading-h2">About us</h2>
                <p className="para para-gray mt-4">
                  TrypScanner, formerly The Elite Explorers is a renowned travel company based in Delhi NCR that offers a comprehensive range of Adventure, Leisure, Pilgrimage, and International Tours. With a passion for exploration and a commitment to providing exceptional travel experiences we are committed to Excellent Management, Personalized service and Group Tour, and Responsible Tourism, TrypScanner has established itself as a trusted name in the industry and we invite all travellers to embark on extraordinary journeys, where they can discover the beauty of the world and create cherished memories that will last a lifetime.
                </p>
                <p className="para para-gray mt-4">
                  <b>Our Motto -</b> "DO EVERYTHING OR DO NOTHING, EITHER WAY, YOUR TRIP IS GOING TO BE EXTRAORDINARY."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about-section 2 */}
      <div className="about-section-2 margin-100">
        <div className="container-section">
          <div className="about-content">
            <h2 className="heading-h2">Pharetra Etiam Inceptos</h2>
            <p className="para mt-4">
              Etiam porta sem malesuada magna mollis euismod. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas
              faucibus mollis interdum. Donec id elit non mi porta gravida at
              eget metus. Aenean lacinia.
            </p>
            {/* <div className="row mt-4">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <ul className="ul">
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Vestibulum id ligula
                  </li>
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Egestas Fermentum
                  </li>
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Ultricies Bibendum
                  </li>
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Fermentum Tellus
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <ul className="ul">
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Mattis Fringilla Ultricies
                  </li>
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Adipiscing Vulputate
                  </li>
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Fringilla Quam Elit
                  </li>
                  <li>
                    <span>
                      <FaCircleCheck />
                    </span>{" "}
                    Commodo Vestibulum
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* about section 3 */}
      <div className="about-section-3 ">
        <div className="about-service-section">
          <div className="">
            <h2 className="heading-h2">About Services</h2>
            <p className="para para-gray">
              Etiam porta sem malesuada magna mollis euismod. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor.
            </p>
            <p className="para para-gray">
              Maecenas faucibus mollis interdum. Donec id elit non mi porta
              gravida at eget metus. Aenean lacinia. Donec ullamcorper nulla non
              metus auctor fringilla. Donec ullamcorper nulla non metus auctor
              fringilla. Aenean eu leo quam. Pellentesque ornare sem lacinia
              quam
            </p>
            <button className="search-btn">Get A Quote</button>
          </div>
          <div className="">
            <h5 className="heading-h5">Ornare Quam Justo Tellusv</h5>
            <p className="para">
              Maecenas faucibus mollis interdum. Donec id elit non mi porta
              gravida at eget metus. Aenean lacinia.{" "}
            </p>
            <h5 className="heading-h5">Pellentesque Magna Venenatis</h5>
            <p className="para">
              Maecenas faucibus mollis interdum. Donec id elit non mi porta
              gravida at eget metus. Aenean lacinia.{" "}
            </p>
            <h5 className="heading-h5">Magna Nibh Commodo</h5>
            <p className="para">
              Maecenas faucibus mollis interdum. Donec id elit non mi porta
              gravida at eget metus. Aenean lacinia.{" "}
            </p>
          </div>
        </div>
      </div>

      {/* about our team */}
      {/* <div className="browser-tour-section margin-100">
        <div className="container-section mt-5">
          <div className="home-destination-section position-relative">
            <h2 className="heading-h3 text-center">Our Team</h2>
            <h1 className="heading-h1 text-center blur-text">Team</h1>
            <div className="row pt-2">
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="team-img-box">
                  <div className="team-img">
                    <Link to={""}>
                      <img src={Manali} alt="" />
                    </Link>
                  </div>
                  <div class="team-content">
                    <h5 className="heading-h5 mb-4">
                      <Link to={""} className="text-black">
                        <span></span>
                        JEANETTE KINGSTON
                      </Link>
                    </h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="team-img-box">
                  <div className="team-img">
                    <Link to={""}>
                      <img src={Manali} alt="" />
                    </Link>
                  </div>
                  <div class="team-content">
                    <h5 className="heading-h5 mb-4">
                      <Link to={""} className="text-black">
                        <span></span>
                        JEANETTE KINGSTON
                      </Link>
                    </h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="team-img-box">
                  <div className="team-img">
                    <Link to={""}>
                      <img src={Manali} alt="" />
                    </Link>
                  </div>
                  <div class="team-content">
                    <h5 className="heading-h5 mb-4">
                      <Link to={""} className="text-black">
                        <span></span>
                        JEANETTE KINGSTON
                      </Link>
                    </h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="team-img-box">
                  <div className="team-img">
                    <Link to={""}>
                      <img src={Manali} alt="" />
                    </Link>
                  </div>
                  <div class="team-content">
                    <h5 className="heading-h5 mb-4">
                      <Link to={""} className="text-black">
                        <span></span>
                        JEANETTE KINGSTON
                      </Link>
                    </h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="team-img-box">
                  <div className="team-img">
                    <Link to={""}>
                      <img src={Manali} alt="" />
                    </Link>
                  </div>
                  <div class="team-content">
                    <h5 className="heading-h5 mb-4">
                      <Link to={""} className="text-black">
                        <span></span>
                        JEANETTE KINGSTON
                      </Link>
                    </h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="team-img-box">
                  <div className="team-img">
                    <Link to={""}>
                      <img src={Manali} alt="" />
                    </Link>
                  </div>
                  <div class="team-content">
                    <h5 className="heading-h5 mb-4">
                      <Link to={""} className="text-black">
                        <span></span>
                        JEANETTE KINGSTON
                      </Link>
                    </h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutBanner;
