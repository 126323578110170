import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import "./faq.css";
export const TripsFaq = ({ itinerarySubData }) => {
  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(i);
    }
    setSelected(i);
  };
  console.log("itinerarySubData", itinerarySubData)
  return (
    <>
      <div className="wrapper">
        <div className="accordion">
          {itinerarySubData.map((item, i) => (
            <div className="item items" key={i}>
              <div className={selected === i ? "title active" : "title"} onClick={() => toggle(i)}>
                <h5
                  className={
                    selected === i ? "heading-h5 active" : "heading-h5"
                  }
                >
                  {item.itineraryHeading}
                </h5>
                {/* <span
                  className={selected === i ? "faq-icons active" : "faq-icons"}
                >
                  {selected === i ? (
                    <IoIosArrowDown className="" />
                  ) : (
                    <IoIosArrowDown />
                  )}
                </span> */}
              </div>
              <div className={selected === i ? "content show" : "content"}>
                <p className=" ">{item.itineraryText1}</p>
                <p className=" ">{item.itineraryText2}</p>
                <p className=" ">{item.itineraryText3}</p>
                <p className=" ">{item.itineraryText4}</p>
                <p className=" ">{item.itineraryText5}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

