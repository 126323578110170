import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { FaQuoteLeft } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
// import required modules
import { Pagination } from "swiper/modules";
import Client1 from "../assets/client1.jpg";
import Client2 from "../assets/client2.jpg";
import Client3 from "../assets/client3.jpg";
import Client4 from "../assets/client4.jpg";
import Client5 from "../assets/client5.jpg";
import Client6 from "../assets/client6.jpg";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import Manali from "../assets/manali/manali1.webp";

import KashmirArticle from "../assets/kashmir/kashmir8.webp"
import LadakhArticle from "../assets/ladakh/ladakh5.webp"
import Kasmir from "../assets/slider1.jpg";
import Ladakh from "../assets/ladakh.avif";
const TravelHighligh = () => {
  return (
    <>
      <div className="travel-heighlight-section margin-100">
        <div className="heighlight-container">
          <h2 className="heading-h2 text-center text-white">
            Traveling Highlights
          </h2>
          <p>Your New Traveling Idea</p>
        </div>
      </div>

      {/* customer review  */}
      <div className="container-section margin-100 position-relative">
        <h3 className="heading-h3 text-center">Customer Review</h3>
        <span className="text-heading-span text-center blur-text">Testimonial</span>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper margin-50 laptop-mode"
        >
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                Wonderfull experience with elite explore After searching many travel companies I've Decided to book with Elite Explores and Mr. Krishan offered me best price and best services for Leh ladakh road trip. Your team was incredibly helpful and friendly,
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client1} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Mofiz Ahmed</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                We had an amazing experience with Elite Travels on their Dho Dhaam Yatra. Their meticulous planning ensured a hassle-free journey,and their attention to detail was impressive. The accommodations were comfortable. I highly recommend Elite Travels
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client2} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">DM Deepu</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                LADAKH is not a place its a paradise. hidden with full of surprises. Dont expect too much We will not get any luxurious services. God only know what is going to happen on the way. Its only upon the fate and prayers of our families.
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client3} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Eldho James</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                The trip was very wonderfully executed by their on ground tour partners. We were guided by two teams, one util Leh and the other util Manali. Both the teams were very supportive, caring, efficient and well versed with local weather.
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client4} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Anuradha Agrawal</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                This years 3rd of Jun 2023 we went trip Srinagar to Manali Via Leh. Whole trip was gone well without any hurdles. My Customer support person was Manish he was give us good deal for this trip strongly recommend this company and also Operation head Kishan
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client5} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Nikesh Pannamaneni</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                This years 3rd of Jun 2023 we went trip Srinagar to Manali Via Leh. Whole trip was gone well without any hurdles. My Customer support person was Manish he was give us good deal for this trip strongly recommend this company and also Operation head Kishan
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client6} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Nikesh Pannamaneni</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>


        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper margin-50 phone-mode"
        >
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                Wonderfull experience with elite explore After searching many travel companies I've Decided to book with Elite Explores and Mr. Krishan offered me best price and best services for Leh ladakh road trip. Your team was incredibly helpful and friendly,
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client1} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Mofiz Ahmed</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                We had an amazing experience with Elite Travels on their Dho Dhaam Yatra. Their meticulous planning ensured a hassle-free journey,and their attention to detail was impressive. The accommodations were comfortable. I highly recommend Elite Travels
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client2} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">DM Deepu</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                LADAKH is not a place its a paradise. hidden with full of surprises. Dont expect too much We will not get any luxurious services. God only know what is going to happen on the way. Its only upon the fate and prayers of our families.
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client3} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Eldho James</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                The trip was very wonderfully executed by their on ground tour partners. We were guided by two teams, one util Leh and the other util Manali. Both the teams were very supportive, caring, efficient and well versed with local weather.
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client4} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Anuradha Agrawal</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                This years 3rd of Jun 2023 we went trip Srinagar to Manali Via Leh. Whole trip was gone well without any hurdles. My Customer support person was Manish he was give us good deal for this trip strongly recommend this company and also Operation head Kishan
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client5} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Nikesh Pannamaneni</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="customer-review-box">
              <h3 className="heading-h3">
                <FaQuoteLeft />
              </h3>
              <p className="para">
                This years 3rd of Jun 2023 we went trip Srinagar to Manali Via Leh. Whole trip was gone well without any hurdles. My Customer support person was Manish he was give us good deal for this trip strongly recommend this company and also Operation head Kishan
              </p>
              <div className="customer-block">
                <div>
                  <img src={Client6} alt="" />
                </div>
                <div>
                  <h5 className="heading-h5">Nikesh Pannamaneni</h5>
                  <div>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                    <span>
                      <FaStar className="star-icon" />
                    </span>
                  </div>
                  <p>TrypScanner</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* brouser tour */}
      <div className="browser-tour-section margin-100">
        <div className="container-section">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="row">
                <h3 className="heading-h3">Browse Tour By Category</h3>
                <div className="col-md-6 col-sm-12">
                  <ul className="browser-ul">
                    <li>Outdoor Activites</li>
                    <li>Cultural & Thematic Tours</li>
                    <li>Family Friendly Tours</li>
                    <li>Holiday & Seasonal Tours</li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul className="browser-ul">
                    <li>City Tours</li>
                    <li>Indulgence & Luxury Tours</li>
                    <li>Relaxation Tours</li>
                    <li>Wild & Adventure Tours</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div>
                <h3 className="heading-h3">Newsletter</h3>
                <div className="margin-50">
                  <p>Subscribe for updates & promotions</p>
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    name=""
                    id=""
                  />
                  <button className="search-btn">SUBSCRIBE</button>
                </div>
                <div className="social-media-icons">
                  <span>
                    <FaFacebookF />
                  </span>
                  <span>
                    <FaPinterestP />
                  </span>
                  <span>
                    <IoLogoInstagram />
                  </span>
                  <span>
                    <FaTwitter />
                  </span>
                  <span>
                    <FaYoutube />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Article */}
      {/* <div className="container-section margin-100">
        <div className="home-destination-section position-relative">
          <h2 className="heading-h3 text-center">Recent Articles</h2>
          <h1 className="heading-h1 text-center blur-text">Articles</h1>
          <div className="row margin-50">
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="article-section">
                <div className="article-img-box">
                  <Link>
                    <img src={Manali} alt="" />

                    <h5 className="heading-h5 article-content">Manali</h5>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="article-section">
                <div className="article-img-box">
                  <Link>
                    <img src={KashmirArticle} alt="" />

                    <h5 className="heading-h5 article-content">Kashmir</h5>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="article-section">
                <div className="article-img-box">
                  <Link>
                    <img src={LadakhArticle} alt="" />

                    <h5 className="heading-h5 article-content">Ladakh</h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="bg-overlay"></div> */}
    </>
  );
};

export default TravelHighligh;
