import React, { useEffect, useState } from "react";
import HomeSlider from "./HomeSlider";
import PopularDestination from "./PopularDestination";
import PopularTour from "./PopularTour";
import TravelHighligh from "./TravelHighligh";
import "./popup.css"
import axios from "axios";
const HomePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);


  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData); // You can send this data to your backend or handle it as per your requirement

    togglePopup();

    try {
      const data = await axios.post(`http://new1.omgeniustech.com/mail.php?subject=trypscanner&message=name: ${formData.name} email: ${formData.email} subject: ${formData.subject} message: ${formData.message}`)
    } catch (error) {

    }
  };

  useEffect(() => {
    const lastClosedTime = localStorage.getItem('popupClosedTime');
    const fiveSeconds = 5 * 1000; // 5 seconds in milliseconds
    const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    if (!lastClosedTime || (Date.now() - parseInt(lastClosedTime, 10)) >= twentyFourHours) {
      setIsOpen(true);
      const timer = setTimeout(() => {
        setIsOpen(false);
        localStorage.setItem('popupClosedTime', Date.now().toString());
      }, fiveSeconds); // Close popup after 5 seconds
      return () => clearTimeout(timer);
    }
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      localStorage.setItem('popupClosedTime', Date.now().toString());
    } else {
      localStorage.removeItem('popupClosedTime');
    }
  };

  return (
    <div>
      <HomeSlider />
      <PopularDestination />
      <PopularTour />
      <TravelHighligh />
      <div className="home-popup">
        {isOpen && (
          <div className="popup">
            <div className="popup-inner">
              <button onClick={togglePopup} className="close-btn">
                Close
              </button>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Subject:</label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Message:</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
